@import "src/scss/mixins";
.faq-box{
  min-height: 70vh;
  border-top: 1px dashed #a2a0a0;
 .title-box{
   h3{
     //@include h3-text('', $textColorBlack);
     font-family: "Inter", sans-serif;
     font-size: 40px;
     margin-top: 85px;
     @media (max-width:610px ) {
       font-size: 22px;
       margin-top: 40px;
     }
   }
   p{
     @include paragraphText();
     margin-top: 20px;
     @media (max-width:610px ) {
       font-size: 16px
     }
   }
   max-width: 850px;
   margin: 0 auto;
   text-align: center;
 }
}
.faq-container{
  margin-top: 40px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
  row-gap: 10px;
  transition: .3s;
  margin-bottom: 100px;
  @media (max-width: 1210px) {
    grid-template-columns: 1fr ;
  }
  @media (max-width:610px ) {
    margin-bottom: 50px;
  }
  h4{
    @include h4-text($textColorBlack,22px);
    @media (max-width:610px ) {
      font-size: 18px;
    }
  }
  p{
    @include paragraphText(20px);
    color: $textColorGrey;
  }
  .btn-func{
    position: absolute;
    right: 0;
    top: 11px;
  }
  .faq-item {
    background: #FFF;
    //border: 1px solid #FFF;
    cursor: pointer;
    transition: .3s;
    position: relative;
    padding: 10px 0;
    min-height: 70px;
    > p{
      margin-top: 10px;
    }
  }

  .faq-item.active {
    background-color: #FFF;
    height: unset;
  }

  .faq-question {
    //padding: 10px;
    font-weight: bold;
    padding: 14px 20px;
  }

  .faq-answer {
    transition: visibility .3s;
    max-height: 0;
    opacity: 0;
    padding: 0 20px;
    border-top:1px solid ;
    //visibility: hidden;
    //border-top: 1px solid #E7E5E5;
  }

  .faq-item.active .faq-answer {

    padding: 0 20px;
    padding-top: 27px;
    visibility: visible;
    max-height: 100%;
    opacity: 1;
  }
}
