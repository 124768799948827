@import "src/scss/mixins";

.dashboard-wrapper {
  height: calc(100vh - 283px);
  display: flex;
  align-items: center;
  overflow-y: scroll;
.container{
  max-width: 1240px;
}
  .top-button {
    float: right;
    margin-bottom: 20px;
    .super-admin-btn{
      transform: scale(1.8);
    }
  }

  .add-custom-btn,.add-custom-btn-blue {
    background: #FFFFFF;
    border-radius: 60px;
    border: none !important;
    min-width: 0;
    padding: 11px;
    transition: .3s;
    &:hover{
      background: #FFFFFF;
      opacity: 0.8;
    }
    img{
      width: 16px;
      height: 16px;
    }
  }
  .add-custom-btn-blue{
    background: $backgroundBlue;
    &:hover{
      background: $backgroundBlue;
      opacity: 0.8;
    }
  }
  .custom-style-table{
    .action-box{
      justify-content: end;
      margin-right: 30px;
    }

    thead{
      background: white;
      th{
        border: none;
        font-weight: 500;
      }
    }
    &.device{
      thead tr th:nth-child(4){
        padding-left: 50px;
      }
    }
    tbody{
      background: #f3f2f2;
      tr:nth-child(2n){
        background: white;
      }
      td{
        color: #7C7C7C;
        border: none;
        .box-calibration{
          display: flex;
          align-items: center;
          > div{
            margin-right: 10px;
            min-width: 50px;
          }
        }
        .edit-icon{
          margin-right: 15px;
          margin-top: 4px;
        }
        .calibration-btn{
          font-family: 'Gilroy-Medium';
          padding: 10px 15px;
          border-radius: 30px;
          border: 1px solid $backgroundBlue;
          max-width: 90px;
          color: black;
          font-weight: 600;
          transition: .3s;
          cursor: pointer;
          &:hover{
            opacity: .8;
          }
        }
        .status-box{
          display: flex;

          .icon-box{
            margin-right: 5px;
            img{
              width: 30px;
              position: relative;
              left: -19px;
              bottom: -1px;
              transition: .3s;
              cursor: pointer;
              &:hover{
                transform: rotate(45deg);
              }
            }
          }
        }
        .custom-box-status{
          background: rgba(30, 119, 60, 0.1);
          padding: 3px 10px;
          width: unset;
          max-width: 37px;
          border-radius: 3px;

          span{
            font-weight: 600;
          }
          &.off{
            background: rgba(162, 160, 160, 0.3);
          }
        }
      }
    }
  }
}
.form-box{
  flex-direction: column;
  gap: 5px;
  .MuiFormHelperText-root{
    text-align: right;
    font-size: 10px;
  }
}

.btn-add-device {
  .MuiButtonBase-root{
    background: $backgroundBlue;
    border-radius: 20px;
    border: none !important;
    color: white;
    transition: .3s;
    & > span svg{
      font-size: 15px;
    }
    &:hover{
      color: black;
    }
  }
}
