@import "src/scss/mixins";
.buy {
  border-bottom: 1px dashed #a2a0a0;

  .box {
    margin: 66px auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    @media (max-width: 980px) {
      margin: 45px auto;
    }
    h2 {
      display: inline;
      @include h2-text($backgroundBlue);
      text-align: center;
      font-family: Gilroy-SemiBold, sans-serif;
      span {
        color: $textColorOrange;
      }
    }

    @media (max-width: 980px) {
      > div{
        display: flex;
        flex-direction: column;
      }
    }
    button {
      @include button-blue();
      font-family: Gilroy-SemiBold, sans-serif;
      font-weight: 200;
      display: inline;
      margin-bottom: 10px;
      position: relative;
      bottom: 20px;
      left: 10px;
      @media (max-width: 980px) {
        bottom: unset;
        left: unset;
      }
    }
  }
}
