@import "src/scss/mixins";

footer {
  padding: 50px 0;
  background: $backgroundBlue;
  @media (max-width: 500px) {
    padding-bottom: 30px;
  }
  .box {

    .first-box{
      @media (max-width: 883px) {
        display: flex;
        justify-content: space-between;
        margin-bottom: 30px;
      }

      & > div {
        display: none;

        @media (max-width: 883px) {
          display: block;
        }
      }
    }
    .nav-box {
      display: flex;
      justify-content: space-between;
      margin-top: 25px;
      align-items: baseline;
      @media (max-width: 1440px) {
        align-items: self-end;
        margin-top: 0px;
        margin-bottom: 30px;

      }
      @media (max-width: 500px) {
        margin-bottom: 0;
      }

    }
    .first-box-nav {
      display: flex;
      @media (max-width: 1440px) {
        flex-flow: wrap;
        gap: 10px;
      }
      @media (max-width: 883px) {
        display: flex;
        justify-content: space-between;
        margin-bottom: 30px;
      }
      @media (max-width: 638px) {
        flex-direction: column;
      }
    }

    ul {
      max-height: 16px;
      display: flex;
      gap: 50px;
      @media (max-width: 1630px) {
        gap: 20px;
      }
      @media (max-width: 638px) {
        flex-direction: column;
        max-height: unset;
      }
      list-style: none;

      &.social {
        margin: 0 50px;
        padding: 0 50px;

        @media (max-width: 1630px) {
          margin: 0 20px;
          padding: 0 20px;
        }
        @media (max-width: 1277px) {

          margin-left: 0;

        }

        border-left: 1px solid #fff;
        border-right: 1px solid #fff;
        @media (max-width: 638px) {
          border: none;
          margin: unset;
          padding: unset;
          padding-top: 50px;
          padding-bottom: 50px;
        }
        a {
          color: #fff;
        }
      }

      a {
        @include paragraphText(16px);
        color: $textColorGrey;
        text-decoration: none;
        transition: .3s;

        &:hover {
          color: $textColorOrange;
        }
      }


    }

    .copyright {
      @include paragraphText(16px);
      color: #7C7C7C;
    }

    .box-btn {
      max-width: 225px;
      @media (max-width: 883px) {
        display: none;
      }
    }

    button {
      @include button-white();
      min-width: 225px;
      @media (max-width: 883px) {
        padding: 9px 32px;
        min-width: 189px;
        max-width: 189px;
        font-size: 14px;
      }
    }
  }
}
