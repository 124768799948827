@import "src/scss/mixins";

.NoMatch-wrapper {
  min-height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  h1{
    @include h1-text($textColorBlue,80px);
    text-align: center;
    //@include color($textColorBlue);
    //font-size: 80px;
  }
  h3{
    @include h3-text();
    @include color($textColorBlue);
  }
  .btn-back{
    margin-top: 10px;
    @include button-blue();
  }
}
