@import "src/scss/mixins";
.location-box {
  min-height: 75vh;
  border-top: 1px dashed #a2a0a0;
  padding: 0 80px;
  @media (max-width: 550px) {
    padding: 0;

  }
  .container{
    //display: flex;
    //flex-direction: row;
    //gap: 20px;
    //@media (max-width: 1399px) {
    //  flex-direction: column;
    //}
    display:inline-block;
    @media (max-width: 1400px) {
      display: flex;
      flex-direction: column;
    }
  }
  .crumbs {
    margin: 30px 0 40px;
    @include paragraphText(16px);
    color: $textColorBlack;

    a {
      color: $textColorGrey;
      text-decoration: none;
      transition: .3s;

      &:hover {
        color: $textColorOrange;
      }
    }
  }
  h2 {
    @include h2-text($textColorBlack, '');
    margin-bottom: 40px;

    @media (min-width: 300px) {
      font-size: 30px;
    }
    @media (max-width: 980px) {
      font-size: 20px;
    }
  }
  h4{
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 26px;
    color: #000000;
  }
  p{
    font-family: Gilroy-Medium;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    padding-top: 20px;
    a.link{
      display: block;
      padding-top: 20px;
      color: #000000;
      text-transform: none;
      text-decoration: none;
      &:hover{
        color: #010035;
      }
    }
  }
  img{
    padding-bottom: 30px;
    padding-top: 30px;
    max-width: 400px;
    width: 100%;
  }
}

