@import "../../scss/mixins";
header{
  //background: green;
  &.fixed{
    position: absolute;
    width: 100%;
  }
  .box{
    padding: 27px 0 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .btn{
    display: flex;
    align-items: center;
    gap: 40px;

    .row-btn{
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 5px;
      z-index: 9999;
      background: none;
      border: none;
      @include button-text(14px);
      span{
        @media (max-width: 630px) {
          display: none;
        }
        }
    }
  }
}
