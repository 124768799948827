@import "src/scss/mixins";
.about-company-box {
  min-height: 75vh;
  border-top: 1px dashed #a2a0a0;
  .container{
    //display: flex;
    //flex-direction: row;
    //gap: 20px;
    //@media (max-width: 1399px) {
    //  flex-direction: column;
    //}
    display:inline-block;
    @media (max-width: 1400px) {
      display: flex;
      flex-direction: column;
    }
  }
  .crumbs {
    margin: 20px 0 40px;
    @include paragraphText(16px);
    color: $textColorBlack;

    a {
      color: $textColorGrey;
      text-decoration: none;
      transition: .3s;

      &:hover {
        color: $textColorOrange;
      }
    }
  }
  h2 {
    @include h2-text($textColorBlack, '');
    margin-bottom: 40px;

    @media (min-width: 300px) {
      font-size: 30px;
    }
    @media (max-width: 980px) {
      font-size: 20px;
    }
  }
  .left-box {
    max-width: 40%;
    float: left;
    @media (max-width: 1588px) {
      max-width: 35%;
    }
    @media (max-width: 1470px) {
      max-width: 30%;
    }
    @media (max-width: 1400px) {
      max-width: 800px;
      float: unset;
    }
    @media (max-width: 1399px) {
      order: 2;
      padding-right: 0;
    }
    h2{
      @media (max-width: 1399px) {
        display: none;
      }

    }
    h4 {
      @include h4-text($textColorBlack, 22px);
      margin-top: 20px;

      @media (min-width: 300px) {
        font-size: 22px;
        font-weight: 400;
        font-family: Gilroy-Medium, sans-serif;
      }
      @media (max-width: 1560px) {
        font-size: 18px;
      }
      @media (max-width: 980px) {
        font-size: 16px;
      }
    }

    .box-info {
      margin-top: 40px;
      max-width: 500px;

      p {
        @include paragraphText(16px);
        color: $textColorGrey;
        margin-bottom: 10px;
        @media (max-width: 980px) {
          font-size: 14px;
        }
        a {
          color: $textColorGrey;
          &:hover{
            color: $textColorOrange;
          }
        }
      }
    }
  }
  .right-box{
    float: right;
    max-width: 60%;
    padding-left: 40px;
    margin-bottom: 105px;
    @media (max-width: 1588px) {
      max-width: 65%;
    }
    @media (max-width: 1470px) {
      max-width: 70%;
    }
    @media (max-width: 1399px) {
      order: 1;
      margin-top: 0;
      max-width: 100%;
      float: unset;
      margin-bottom: 20px;
      padding-left: 0;
    }
    h2{
      @media (min-width: 1400px) {
        display: none;
      }
    }
    .box{
      display: flex;

    }
    .arrow{
      @include NoteworthyText();
      color: $textColorBlue;
      font-size: 22px;
      max-width: 170px;
      text-align: left;
      position: absolute;
      @media (max-width: 720px) {
        font-size: 16px;
      }
    }
    .one{
      position: relative;
      display: block;
      .arrow{
        margin: 0 auto;
        bottom: 0;
        right: 0;
        left: 0;
        svg{
          position: absolute;
          top: -30px;
          left: -40px;
          @media (max-width: 720px) {
            left: 20px;
          }
        }
      }
    }
    .two{
      position: relative;
      display: block;
      margin-left: 20px;
      padding-top: 121px;
      @media (max-width: 700px) {
        padding-top: 92px;
      }
      .arrow{
        right: 0;
        left: 0;
        margin: 0 auto;
        top: 0;
        svg{
          position: absolute;
          left: -50px;
          bottom: -30px;
          @media (max-width: 720px) {
            left: 50px;
          }
        }
      }
    }
    @media (max-width: 935px) {
    .one,.two{
      max-width: 50%;
      img{
        max-width: 415px;
        width: 100%;
      }
    }
    }
  }
}

