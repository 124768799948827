@import "src/scss/mixins";
.box-info {
  margin: 100px 0;
  @media (max-width: 570px) {
    margin: 50px 0 60px;
  }
  .box-container {
    display: flex;
    gap: 20px;
    @media (max-width: 570px) {
        flex-direction: column;
    }
    .box {
      display: flex;
      justify-content: space-between;
      background: $backgroundBlue;
      width: 100%;
      padding: 50px 20px;
      @media (max-width: 1040px) {
        > img{
          display: none;
        }
      }
      @media (max-width: 570px) {
        display: unset;
        > div{
          display: flex;justify-content: space-between;
        }
        padding: 25px;
      }
      .box-text {
        min-height: 98px;
        @media (max-width: 1040px) {
          min-height: 80px;
        }
        h3 {
          font-size: 40px;

          font-family: Gilroy-Medium, sans-serif;

          font-style: normal;
          font-weight: 400;
          line-height: normal;
          transition: 0.3s;
          color: #FFFFFF;

          @media (max-width: 500px) {
            font-size: 30px;
            max-width: 70%;
          }
        }
        p {
          @include paragraphText();
          color: $textColorWhite;
          font-size: 16px;
          margin-top: 10px;
        }

      }
      img{
        max-width: 104px;
        max-height: 104px;
      }
      a {
        display: inline-block;
        margin-top: 40px;
        transition: .3s;
        @media (max-width: 1040px) {
          margin-top: 0;
        }
          &:hover {
          transform: translate(10px);
        }
      }
    }
  }
}
