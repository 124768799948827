@import "src/scss/mixins";

.privacy-policy {
  border-top: 1px dashed rgba(0, 0, 0, 0.3);
  margin-bottom: 50px;
  h1 {
    margin-top: 40px;
    font-family: "Inter", sans-serif;
    font-size: 40px;
    @media (max-width: 610px) {
      font-size: 22px;
    }
  }
  h2{
    font-family:"Inter", sans-serif;
    margin-top: 20px;
  }
  p{
    font-family: Gilroy-Medium, sans-serif;
  }
  ul{
    li{
      font-family: Gilroy-Medium, sans-serif;
    }
      padding-left: 30px;
  }
}
