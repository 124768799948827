@import "src/scss/mixins";

.shop-wrapper {
  position: relative;
  border-top: 1px dashed rgba(0, 0, 0, 0.3);
  DISPLAY: GRID;
  grid-template-columns: 41% 54%;

  width: 100%;

  @media (max-width: 1258px) {
    grid-template-columns: 45% 54%;
  }
  @media (max-width: 825px) {
    display: flex;
  }


  .left-box {

    display: flex;
    position: sticky;
    top: 0;
    //align-items: flex-end;
    max-height: 835px;
    //@media (max-width: 1500px) {
      //align-items: center;
    //}

    @media (max-width: 1100px) {
      flex-direction: column;
    }
    .product-box {

      position: relative;
      //background-color: #D9D9D9;
      max-width: 840px;
      width: 100%;
      height: 100%;
      //padding-top: 10px;
      display: flex;
      justify-content: center;
      @media (max-width: 1500px) {
        max-width: 740px;
      }
      overflow: hidden;
    }

    .left-cubes {
      margin-left: 20px;
      display: grid;
      gap: 20px;
      margin-bottom: 40px;
      max-height: 300px;
      margin-top: 30px;

      @media (max-width: 1500px) {
        gap: 10px;
      }
      @media (max-width: 1100px) {
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        max-height: calc(120px + 70px);
        margin: 0;
        padding-top: 20px;
        padding-bottom: 50px;
      }
      @media (max-width: 825px) {
        display: none;
      }


      div {
        cursor: pointer;
        width: 110px;
        height: 110px;
        overflow: hidden;
        background: #010035;
        @media (max-width: 1500px) {
          width: 75px;
          height: 75px;
        }
        @media (max-width: 1100px) {
          min-width: 75px;
          width: 100%;
          max-width: 120px;
          height: 100%;
        }
        img{
          width: 100%;
        }
      }
    }

    @media (max-width: 1680px) {
      background-size: 50%;
    }

    @media (max-width: 1380px) {
      background-size: 70%;
    }
    @media (max-width: 825px) {
      display: none;
    }
  }

  .right-box {
    margin-top: 25px;
    padding-left: 30px;
    @media (max-width: 1620px) {
      padding-right: 50px;
    }
    @media (max-width: 825px) {
      padding: 0;
    }

    .breadcrumbs {
      a{
        text-decoration: none;
        color: #7C7C7C;
      }
      @include h5-text();
      color: #7C7C7C;
      @media (max-width: 825px) {
        padding: 0 50px;
      }
      @media (max-width: 630px) {
        padding: 0 15px;
      }
      span {
        color: #000000;
      }
    }

    .title-and-price {
      margin-top: 21px;
      @media (max-width: 825px) {
        padding: 0 50px;
        margin-top: 30px;
      }
      @media (max-width: 630px) {
        padding: 0 15px;
      }
      h2 {
        @include h3-text();
        color: #000;
      }

      .price {
        @include paragraphText(18px);
        color: #000;

        span {
          font-size: 30px;
          font-weight: 700;
          @media (max-width: 500px) {
            font-size: 25px;
          }
        }
      }

      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }

    .box-product {
      display: none;
      width: 100%;
      .bg-img{
      //  background: #D9D9D9;
      //  text-align: center;
        max-height: 700px;
        min-height: 470px;
        overflow: hidden;
        &> div{
          margin-bottom: 10px;
          background-position: center;
          background-size: contain;
          @media (max-width: 825px) {
            max-width: 400px;
            margin: 0 auto;
          }
        }
      }
      @media (max-width: 825px) {

        padding-top: 30px;
        display: block;
        img {
          width: 100%;
        }
        .cubes-mob {
          display: flex;
          justify-content: center;
          gap: 10px;
          min-height: 80px;
          height: 100%;

          div {
            overflow: hidden;
            min-width: 60px;
            max-width: 110px;
            max-height: 110px;
            width: 100%;
            min-height: 80px;
            height: 100%;
            background: #010035;
            background-size: cover;
            background-position: center;
            @media (max-width: 500px) {
              width: 60px;
              height: 60px;
              min-height: unset;
            }
          }
        }
      }
      @media (max-width: 500px) {
        padding-top: 15px;
      }

      }

    .description {
      @include paragraphText(22px);
      margin-top: 50px;
      @media (max-width: 968px) {
        margin-top: 20px;
      }
      @media (max-width: 825px) {
        padding: 0 50px;
        margin-top: 36px;
      }
      @media (max-width: 630px) {
        padding: 0 15px;
        margin-top: 26px;
      }
      b{
        font-weight: 700;
      }
    }


    .box-shop {
      //margin-left: 152px;
      //max-width: 830px;
      @media (max-width: 1500px) {
        //margin-left: 92px;
      }
      @media (max-width: 968px) {
        margin-left: 0;
        position: relative;
      }
    }

    .box-shop-next {
      margin-top: 40px;
      display: flex;
      flex-direction: row;
      align-items: end;
      @media (max-width: 825px) {
        padding: 0 50px;
        margin-top: 24px;
      }
      @media (max-width: 630px) {
        padding: 0 15px;
      }

      .right-content {
        //margin-left: 40px;
        width: 100%;
        @media (max-width: 1500px) {
          //margin-left: 20px;
        }
        @media (max-width: 968px) {
          margin-left: 0;
        }

        .select-qty {
          display: grid;
          grid-template-columns: 335px 52px 36px;
          @media (max-width: 450px) {
            grid-template-columns: 275px 52px 36px;
          }
          @media (max-width: 425px) {
            grid-template-columns: 70% 52px 36px;
          }

          div {
            @include paragraphText(18px);
            padding: 10px 0;
          }

          > div:nth-child(1) {

            border-bottom: 1px solid;

            img {
              position: relative;
              bottom: -1px;
              margin-right: 7px;
            }

            span {
              display: block;
              max-width: 93%;
            }
          }

          > div:nth-child(2) {
            padding-right: 30px;
            border-bottom: 1px solid;
            border-right: 1px solid;
          }

          > div:nth-child(3) {
            padding-left: 20px;
            border-bottom: 1px solid;
          }

          > div:nth-child(4) {
            //border-bottom: 1px solid;
            display: flex;

            div {
              padding-left: 120px;
            }
          }

          > div:nth-child(5) {
            padding-right: 30px;
            //border-bottom: 1px solid;
            border-right: 1px solid;
          }

          > div:nth-child(6) {
            padding-left: 20px;
            //border-bottom: 1px solid;
            cursor: pointer;
          }

          div {
            display: flex;
            align-items: center;
            border-color: #A2A0A0 !important;
            cursor: pointer;
          }
        }

        .field-form {
          > div {
            max-width: 425px;
            @media (max-width: 968px) {
              max-width: 100%;
            }
          }

        }

        .description-price {
          margin-bottom: 27px;
          border-top: 1px solid;
          border-bottom: 1px solid;
          border-color: #a2a0a0;
          //margin-top: -11px;
          z-index: 99999;
          position: relative;
          padding-top: 42px;
          padding-bottom: 30px;
              @media (max-width: 825px) {
                padding-top: 25px;
                border: none;
              }
          @media (max-width: 500px) {
            margin-bottom: 0;
          }
          h3 {
            @include h4-text();
            color: #000;
          }

          p {
            @include paragraphText();
          }

          .item-box {
            display: flex;
            justify-content: space-between;
            @media (max-width: 570px) {
              flex-direction: column;
            }

            .one-item {
              max-width: 450px;
              @media (max-width: 1380px) {
                margin-right: 20px;
              }
              .count-title {
                display: none;
                @media (max-width: 825px) {
                  margin: 0;
                  display: block;
                }
              }
              .title-p{
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 20px;
              }
              @media (max-width: 825px) {
                margin: 0;
              }
            }

            .two-item {
              @media (max-width: 425px) {
                display: flex;
                flex-direction: column-reverse;
              }
              @include paragraphText(18px);
              @media (max-width: 825px) {
                margin-top: 25px;
              }
              .count-title {
                @media (max-width: 825px) {
                  display: none;
                }
              }

              .count-box {
                display: flex;

                .count-box-item {
                  display: grid;
                  grid-template-columns: 110px 45px 30px;
                  border-color: #A2A0A0;
                  border: 1px solid;
                  border-left: none;
                  border-color: #A2A0A0;
                  padding: 20px 0;
                  @media (max-width: 825px) {
                    width: 100%;
                    grid-template-columns: 52.85% 37.89% 9.26%;
                  }
                  @media (max-width: 430px) {
                    grid-template-columns: 42.85% 37.89% 9.26%;
                  }
                   div:nth-child(2){
                     width: 5px;
                   }
                }

                .plus, .minus {
                  cursor: pointer;

                }

                .plus {
                  padding-right: 30px;
                }

                .minus {
                  border-color: #A2A0A0;
                  border-top: 1px solid;
                  border-bottom: 1px solid;
                  padding: 20px 0;
                  padding-left: 20px;
                  border-color: #A2A0A0;
                }
              }

            }
            .count-title {
              @include paragraphText();
              text-align: right;
              margin-bottom: 15px;

              @media (max-width: 425px) {
                margin-top: 30px;
              }
              span {
                @include h3-text();
                color: #000;
              }
            }
          }
        }

        .total-box {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 50px;
          @media (max-width: 1267px) {
            align-items: self-start;
            flex-direction: column;
          }
          @media (max-width: 500px) {
            align-items: self-start;
            flex-direction: column;
            margin-bottom: 30px;
          }

          .total {
            display: flex;
            align-items: center;
            @media (max-width: 1267px) {
              width: 100%;
              justify-content: space-between;
            }
            @media (max-width: 825px) {
              margin-top: 20px;
              justify-content: space-between;
              width: 100%;
            }
          }

          .total-price {
            @include h3-text();
            color: $textColorBlue;
            width: 230px;
            @media (max-width: 500px) {
              width: unset;
            }
            span {
              @include h2-text();
              color: $textColorBlue;
              font-size: 50px !important;

              @media (max-width: 500px) {
                font-size: 25px !important;
              }
            }

          }

          .btn-buy {
            text-decoration: none;
            background: #010035;
            border-radius: 40px;
            color: white;
            padding: 15px 50px;
            @include paragraphText(16px);
            cursor: pointer;
            margin-left: 40px;
            &.disable{
              cursor: not-allowed;
            }
          }
        }
      }
    }
  }
}
.tooltip-img{
  //@media max-width(980px) {
  //
  //}
}
