$breakpoint-mobile: 375px;
$breakpoint-tablet: 834px;
$breakpoint-laptop: 1100px;
$breakpoint-desktop: 1440px;
$container-fullHd: 1920px;

$backgroundBlue:#010035;
$backgroundGrey:#E7E5E5;
$backgroundWhite:#FFFFFF;

$textColorBlue:#010035;
$textColorBlack:#000000;
$textColorGrey:#7C7C7C;
$textColorWhite:#FFFFFF;
$textColorOrange:#FFA800;

$h1Size:100px;
$h2Size:77px;
$h3Size:40px;
$h4Size:30px;
$h5Size:16px;
