@import "src/scss/mixins";
.steps {
  .bg {
    background-color: $backgroundBlue;
    background-repeat: no-repeat;
    background-size: cover;
    @media (max-width: 980px) {
      background-position: right;
    }
    .box {
      display: flex;
      align-items: center;
      padding: 100px 0 100px 105px;
      @media (max-width: 1440px) {
        padding: 40px 20px;
      }

      @media (max-width: 1200px) {
        flex-direction: column;
      }
      @media (max-width: 500px) {
        align-items: flex-start;
      }
      h2 {
        color: #FFFFFF;
        max-width: 400px;
        font-family: Gilroy-SemiBold, sans-serif;
        font-size: 57px;
        font-weight: 200;
        padding: 50px 0 50px 30px;
        @media (max-width: 500px) {
          font-size: 37px;
          padding: 0;
          br{
            display: none;
          }
        }
      }

      h4 {
        @include h4-text();
        font-family: Gilroy-SemiBold, sans-serif;
        font-size: 30px;
        margin-top: 10px;
        max-width: 245px;
        font-weight: 200;
        &:first-child,&:last-child{
          min-width: 200px;
        }
        @media (max-width: 500px) {
          font-size: 14px;
        }
      }

      p {
        @include paragraphText();
        color: $textColorOrange;
      }

      .right-box {
        margin-left: 122px;
        display: flex;
        padding-top: 30px;
        @media (max-width: 980px) {
          flex-direction: column;
          margin: 0;
          gap: 35px;
        }
        @media (max-width: 500px) {
          padding-left: 20px;
        }
        > div:nth-child(1) {
          min-width: 170px;
          margin-right: 82px;
        }

        > div:nth-child(2) {
          max-width: 245px;
          margin-right: 65px;
          position: relative;

          &:before {
            content: '';
            background: $textColorWhite;
            height: 50px;
            width: 1px;
            position: absolute;
            left: -45px;
            @media (max-width: 980px) {
              width: 50px;
              height: 1px;
              top: -21px;
              left: 0;
            }
          }
        }

        > div:nth-child(3) {
          min-width: 200px;
          position: relative;

          &:before {
            content: '';
            background: $textColorWhite;
            height: 50px;
            width: 1px;
            position: absolute;
            left: -45px;
            @media (max-width: 980px) {
              width: 50px;
              height: 1px;
              top: -21px;
              left: 0;
            }
          }
        }

        > div{
          @media (max-width: 980px) {
            max-width: 100% !important;
            margin:0 !important;
          }
        }
      }
    }
  }
}
