
@import "src/scss/mixins";
.hero-section {
  background: $backgroundBlue;

  .container {
    height: 100%;
  }

  .box {
    max-width: 100%;
    height: calc(100vh - 100px);
    @media (max-width: 960px) {
      height: calc(100vh - 78px);
    }
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .box-content {
    h1 {
      @include h1-text($textColorWhite, $h1Size);
      max-width: 797px;
      @media (max-width: 1680px) {
        max-width: 760px;
      }
      @media (max-width: 1440px) {
        max-width: 688px;
      }
      @media (max-width: 1200px) {
        max-width: 497px;
      }
      @media (max-width: 960px) {
        max-width: 450px;
      }
      font-family: Gilroy-SemiBold, sans-serif;
    }

    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .box-btn {
      display: flex;
      align-items: center;
      margin-top: 75px;
      @media (max-width: 1680px) {
        margin-top: 65px;
      }
      @media (max-width: 1440px) {
        margin-top: 45px;
      }
      @media (max-width: 630px) {
        flex-direction: column;
        align-items: start;
      }
      a {
        display: flex;
      }

      .box-child{
        display: flex;
        @media (max-width: 630px) {
          margin-top: 30px;
        }
      }

      .btn-white {
        @include button-white();
      }

      div:nth-child(1) {
        margin-right: 17px;

      }

      div:nth-child(2) {
        margin-right: 24px;
        transition: .3s;

        &:hover {
          opacity: 0.8;
        }
      }

      div:nth-child(3) {
        transition: .3s;

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}

