@import "../../scss/mixins";
.popup-overlay{
  position: fixed;
  width: 650px;
  height: 520px;
  background: white;
  left: 0;
  right: 0;
  top: 10%;
  bottom: 0;
  margin: 0 auto;
  padding: 44px 28px;
  @media (max-width: 700px) {
    width: 95%;
  }
  .close-button{
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    padding: 15px;
  }
  .mc__form-container{
    h2{
      @include h2-text($textColorBlack,30px);
      font-size: 30px !important;
      margin-top: 30px;
      text-align: center;
    }
    h4{
      margin-top: 10px;
      @include h4-text($textColorBlack,16px);
    }
    form{
      display: flex;
      flex-direction: column;
      margin-top: 20px;
      input{
        border: none;
        border-bottom: 1px solid $textColorGrey;
        height: 60px;
        font-size: 20px;
        outline: none;
      }
      button{
        margin-top: 30px;
        background: none;
        @include button-blue();
      }
    }
    .message{
      margin-top: 10px;
      @include button-text();
      color: $textColorOrange
    }
  }
}
