@import './variables';
@mixin background-color($color) {
  background-color: $color;
}
@mixin color($color) {
  color: $color;
}

@mixin mediaFontSize($width,$size){
  @media (max-width: $width) {
    transition: .3s;
    font-size: $size
  }
}

@mixin h1-text($textColor:$textColorWhite, $fontSize:$h1Size){
  font-family: "Inter", sans-serif;
  font-size: $fontSize;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  transition: .3s;

  @include color($textColor);
  @include mediaFontSize(1680px,86px);
  @include mediaFontSize(1440px,72px);
  @include mediaFontSize(1200px,58px);
  @include mediaFontSize( 960px,50px);
}


@mixin h2-text($textColor:$textColorWhite, $fontSize:$h2Size){
  font-family: "Inter", sans-serif;
  font-size: $fontSize;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  transition: .3s;

  @include color($textColor);
  @include mediaFontSize(1680px,67px);
  @include mediaFontSize(1440px,58px);
  @include mediaFontSize(1200px,48px);
  @include mediaFontSize(960px,38px);
}

@mixin h3-text($textColor:$textColorWhite, $fontSize:$h3Size){
  font-family: "Inter", sans-serif;
  font-size: $fontSize;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  transition: .3s;
  @include color($textColor);
  @include mediaFontSize(1680px,35px);
  @include mediaFontSize(1440px,30px);
  @include mediaFontSize(1200px,25px);
  @include mediaFontSize( 960px,20px);
}



@mixin h4-text($textColor:$textColorWhite, $fontSize:$h4Size){
  font-family: "Inter", sans-serif;
  font-size: $fontSize;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  transition: .3s;

  @include color($textColor)
}
div{
  outline: none;
}

@mixin h5-text($textColor:$textColorWhite, $fontSize:$h5Size){
  font-family: "Inter", sans-serif;
  font-size: $fontSize;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  transition: .3s;

  @include color($textColor)
}

@mixin button-white(){
  padding: 15px 50px;
  border-radius: 40px;
  background:$backgroundWhite;
  @include button-text;
  text-decoration: none;
  color: $textColorBlack;
  display: block;
  border: 0.1px solid $backgroundWhite;
  transition: .3s;
  cursor: pointer;
  &:hover{
    border: 0.1px solid #000;
    background: #EEE;
  }
}
@mixin button-blue(){
  padding: 15px 50px;
  border-radius: 40px;
  background:$backgroundBlue;
  @include button-text;
  text-decoration: none;
  color: $textColorWhite;
  display: block;
  border: 0.1px solid $backgroundBlue;
  transition: .3s;
  cursor: pointer;
  &:hover{
    border: 0.1px solid #000;
    background: #060553;
  }
}

@mixin button-text($fontSize:16px){
  font-family: "Inter", sans-serif;
  font-size: $fontSize;
  font-style: normal;
  font-weight: 400;
  transition: .3s;
  line-height: normal;
}

@mixin paragraphText($fontSize:22px){
  font-family: "Inter", sans-serif;;
  font-size: $fontSize;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  transition: .3s;
  @media (max-width: 980px) {
    font-size: 16px;
  }
}
@mixin NoteworthyText($fontSize:22px, $color:#FFF){
  text-align: center;
  font-family: Noteworthy,sans-serif;
  color: $color;
  font-size: $fontSize;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}


.container {
  max-width: $container-fullHd;
  padding: 0 50px;
  @media (min-width: 1900px) {
    padding: 0 100px;
  }
  @media (max-width: 630px) {
    padding: 0 15px;
  }
  margin: 0 auto;
}

.text-blue{
  @include color($textColorBlue)
}
.text-white{
  @include color($textColorWhite)
}
.text-black{
  @include color($textColorBlack)
}
.text-grey{
  @include color($textColorGrey)
}
.text-orange{
  @include color($textColorOrange)
}


