.snackbarTab{
    background: #FFFFFF !important;
    border-radius: 8px !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.snackbarTab > div {
    display: flex;
    align-items: center;
}
.snackbarContent{
    display: flex;
    align-items: center;
}
.textShackbar{
    max-width: 230px ;
    padding-left: 12px;
    color: #101828;
}

.closeIcon {
    cursor: pointer;
}
