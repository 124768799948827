.login-wrapper {
  height: calc(100vh - 283px);
  display: flex;
  align-items: center;

  .box-login {
     display: block;
    gap: 10px;
    max-width: 400px;
    width: 100%;

    input {

      padding: 5px;
    }
  }
}
