@import "src/scss/mixins";
.info-product {
  padding-top: 100px;
  @media (max-width: 500px) {
    padding-top: 0;
  }
  .custom-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1725px;
    margin: 0 auto;
    position: relative;
    @media (max-width: 980px) {
      flex-direction: column;
      padding-top: 40px;
    }

    @media (max-width: 500px) {
      padding-bottom: 50px;
    }
  }

  overflow: hidden;
  width: 100vw;

  .box-img {
    width: 100%;
    position: relative;
    max-height: 800px;
    right: 150px;
    bottom: -35px;
    max-width: 50vw;
    min-width: 300px;
    @media (max-width: 1500px) {
      right: 0;
      margin-right: 50px;
    }
    @media (max-width: 980px) {
      order: 2;
    }
    img {
      max-width: 832px;
      @media (max-width: 1600px) {
        width: 100%;
        position: relative;
        bottom: -5px;
      }
    }
  }

  .box-text {
    min-width: 50%;
    max-width: 818px;


    .one-item{
      border-bottom: 1px dashed rgba(0, 0, 0, 0.3);
      padding-bottom: 35px;
      margin-bottom: 35px;
    }
    h3 {
      @include h3-text($textColorBlack, $h3Size);
      font-family: Gilroy-SemiBold, sans-serif;
      font-style: normal;
      font-weight: 200;
      font-size: 40px;
    }

    p {
      margin-top: 20px;
      @include paragraphText();
      font-family:"Inter", sans-serif;;
    }
    .two-item{
     ul{
       margin-top: 20px;
       padding-bottom: 40px;
       list-style: none;
       display: flex;
       gap: 20px;
       @media (max-width: 500px) {
         display: flex;
         flex-direction: column;
         gap: 5px;
         padding-bottom: 0;
       }
       li{
         @include paragraphText();
         font-family:"Inter", sans-serif;;
         max-width: 270px;
         font-size: 22px;
         @media (max-width: 500px) {
           max-width: unset;
         }
       }
     }
    }
  }

}
