@import "variables";
@import "mixins";

@font-face {
  font-family: 'Gilroy-Medium';
  src: url('../assets/fonts/Gilroy-Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'Gilroy-MediumItalic';
  src: url('../assets/fonts/Gilroy-MediumItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'Gilroy-SemiBold';
  src: url('../assets/fonts/Gilroy-SemiBold.ttf') format('truetype');
}
@font-face {
  font-family: 'Noteworthy';
  src: url('../assets/fonts/Noteworthy.ttc') format('truetype');
}
body{
  background: #E7E5E5;
}
.disable-scroll{
  overflow: hidden;
}

.flex{
  display: flex;
}
.pointer{
 cursor: pointer;

  transition: .3s;
  &:hover{
    transform: scale(0.9);
  }
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;

  &::after, &::before {
    box-sizing: border-box;
  }
}
.containerToast{
  position: fixed;
  top: 0;
  right: 0;
}
span,
img,
div{
  transition: .3s;
}
