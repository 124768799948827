
@import "../../../../scss/mixins";
.about-product {
  background: $backgroundBlue;

  .about-product-anchor {
    .container {
      height: 100%;
    }

    height: 100px;
    position: relative;
    @media (max-width: 960px) {
      height: 78px;
    }

    .container {
      display: flex;
      align-items: center;
    }

    h3 {
      @include h3-text($textColorWhite, $h3Size);
      font-family: Gilroy-SemiBold, sans-serif;
    }

    .anchor {
      cursor: pointer;
      color: #fff;
      position: absolute;
      right: 0;
      left: 0;
      text-align: center;
      animation: bounce 1s infinite alternate;
      display: block;
      width: 20px;
      margin: 0 auto;
      @media (max-width: 500px) {
        left: unset;
        right: 30px;
      }
      @keyframes bounce {
        0% {
          transform: translateY(0);
        }
        100% {
          transform: translateY(-20px);
        }
      }
    }
  }

  .about-product-info {
    .box {
      display: flex;
      //justify-content: space-between;
      @media (max-width: 980px) {
        flex-direction: column;
        align-items: center;
        padding-bottom: 60px;
      }
    }

    .left, .right {
      display: flex;
      justify-content: space-between;
      width: 40%;
      @media (max-width: 1400px) {
        flex-direction: column;
        justify-content: center;
        gap: 40px;
      }
      @media (max-width: 500px) {
        width: 100%;
        gap: 20px;
      }
    }

    .right {
      @media (max-width: 1400px) and (min-width: 981px) {
        align-items: end;
      }
    }

    .left {
      @media (max-width: 980px) {
        order: 1;
        margin-top: 40px;
      }
      @media (max-width: 500px) {
        margin-top: 20px;
      }
    }

    .text-box {
      display: flex;
      align-items: center;
      gap: 10px;
      @media (max-width: 1400px) {
        min-width: 320px;
      }
      @media (max-width: 980px) {
        flex-direction: column;
        align-items: self-start;
        min-width: unset;
      }
      @media (max-width: 500px) {

        img{
          max-width: 30px;
        }
      }

      .text {
        @include button-text();
        font-family: Gilroy-Medium, sans-serif;
        color: $textColorWhite;
        max-width: 250px;
        @media (max-width: 500px) {
          max-width: 99%;
          font-size: 14px;
        }
      }
    }

    .product-box {
      min-width: 279px;
      max-height: 347px;
      text-align: center;
      position: relative;
      @media (max-width: 980px) {
        order: 0;
        min-width: unset;
        max-height: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
          transform: rotate(90deg);
        }
      }

      .product{
        //max-width: 65%;
          @media (max-width: 980px) {
            max-width: 70%;
          }
          @media (max-width: 500px) {
            width: 60%;
          }
      }

      .box-text {
        position: absolute;
        right: 0;
        top: 0;
        @media (max-width: 980px) {
          bottom: 0;
          top: unset;
        }
        @media (max-width: 500px) {
          right: 39%;
        }

        img {
          position: relative;
          top: 50px;
          left: 50px;
        }

        .text {
          @include NoteworthyText();
          transform: rotate(-21.935deg);
          position: relative;
          top: -30px;
          left: 54px;
          @media (max-width: 980px) {
            top: 27px;
            left: 90px;
          }
        }
      }

    }
  }
}
